import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /* login(userName: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/login`, {
        userName,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  } */

  login(data) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/login`, data)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  reportlist(): Observable<any>{

    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'scrap/getreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )


  }

  customerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/customers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  todayOrderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'order/all_placed_order_today';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  all_OrderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'order/all_order';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  pickupmanList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/pickmen';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPickupMan(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  categoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  categoryList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addCategory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCategory(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  //SCRAP
  scrapList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'scrap/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addScrap(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'scrap/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getScrap(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'scrap/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateScrap(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'scrap/author/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteScrap(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'scrap/author/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  company(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'company/author';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tenderReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/tenderreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  reviewReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/reviewreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  installerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'installer/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  blogList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'blog/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  projectList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'project/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  testimonialList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'testimonial/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  installerList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'installer/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  openTenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/opentender';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  notOpenTenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/notopentender';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteInstaller(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  awardTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/award/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  closeTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/close/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteInstaller1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  changepassword(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/password/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteBlog(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteProject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteTestimonial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCustomer1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTestimonial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTenderCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/customer/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getBlog(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getProject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getInstaller(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deletePrice(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'scrap/author1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPerformance(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/performance/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getApplicationList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/application/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCustomer(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCompany(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateAddress(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/author1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updatePrice(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'scrap/author1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addAddress(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addUser(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/signup';
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPrice(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'scrap/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addressList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  backendList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/backend';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  stateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'state/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  cityList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'state/authors/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  scrapPrice(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'scrap/author1/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAddress(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteAddress(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  activeUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/user/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deactiveUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/delete/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateApplication(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateTestimonial(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateTender(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  forgotPassword(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/forgotpassword';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateBlog(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateProject(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateInstaller(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateUser(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/user/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateSalary(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/salary/update/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addCustomer(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addBlog(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addProject(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addTestimonial(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInstaller(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInstaller1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addTender(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/data';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  blogUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'blog/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  categoryUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'category/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  scrapUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'scrap/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  logoUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'company/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  profileUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'installer/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  profileUpload1(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'auth/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  projectUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'project/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  testimonialUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'testimonial/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  //all orderlist
  
  
  allOrderList(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_pending_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  allAllotedOrderList(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_alloted_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  all_pickedup_orders(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_pickedup_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  all_washinprogress_orders(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_washinprogress_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  all_readytodeliver_orders(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_readytodeliver_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  all_allotedtodeliveryboy_orders(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_allotedtodeliveryboy_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  all_delivered_orders(): Observable<any>{
    
    let URL = environment.apiUrl + 'order/all_delivered_orders';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  change_my_order_status(id, data): Observable<any>{
    
    let URL = environment.apiUrl + 'order/change_my_order_status/' + id;
    return this.http.put<any>(URL, data).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  //order details
  
  
  orderDetail(id): Observable<any>{
    
    let URL = environment.apiUrl + 'order/orderdetail/' + id;
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  pickManList(): Observable<any>{
    let URL = environment.apiUrl + 'auth/pickmen';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  pickManAllot(id, data): Observable<any>{
    let URL = environment.apiUrl + 'order/pickupmanallot/' + id;
    return this.http.put<any>(URL, data).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deliveryManAllot(id, data): Observable<any>{
    let URL = environment.apiUrl + 'order/deliverymanallot/' + id;
    return this.http.put<any>(URL, data).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
